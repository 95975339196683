import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  blocks: []
}

const blockSlice = createSlice({
  name: "block",
  initialState,
  reducers: {
    addBlock(state, action) {
      state.blocks = action.payload
    },
		getBlock(state, action) {
			return state.blocks.block
		}
  }
})

export const { addBlock, getBlock } = blockSlice.actions
export default blockSlice.reducer