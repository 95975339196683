import React from 'react'

export default function FastNavigate() {
	const fastnav = {
		timer: null,
		open(e) {
			if(!this.timer) this.timer = setTimeout(() => e.target.classList.add('open'), 250)
		},
		close(e) {
			if(this.timer) {
				clearTimeout(this.timer)
				this.timer = null
				e.target.classList.remove('open')
			}
		},
		click(e) {
			// console.log(e.target, e.nativeEvent.offsetY, e.target.clientHeight)
			if(e.target.classList.contains('open') && e.target.clientHeight === 60) {
				if(e.nativeEvent.offsetY <= e.target.clientHeight * .4) window.scrollTo({ top: 0, behavior: 'smooth' })
				else if(e.nativeEvent.offsetY >= e.target.clientHeight * .6) window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth'})
			}
		}
	}

	return (
		<div id="fast-nav" onMouseEnter={ (event) => fastnav.open(event) } onMouseLeave={ (event) => fastnav.close(event) } onClick={ (event) => fastnav.click(event) }></div>
	)
}