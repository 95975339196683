import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/dist/query/react"
import { waterApi } from './api/water.api'
import productsReducer from './slices/products.slice'
import settingsReducer from './slices/settings.slice'
import newsReducer from './slices/news.slice'
import cartReducer from './slices/cart.slice'
import blockReducer from './slices/block.slice'

export const store = configureStore({
  reducer: {
    [ waterApi.reducerPath ] : waterApi.reducer,
    products: productsReducer,
    settings: settingsReducer,
    news: newsReducer,
    cart: cartReducer,
    block: blockReducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(waterApi.middleware)
})

setupListeners(store.dispatch)
