import React from 'react'
import { Outlet } from 'react-router-dom'

import Header from './Header'
import Footer from './Footer'
import GetCartInfo from './GetCartInfo'

function Layout() {
	return (
		<>
			<GetCartInfo/>
			<Header/>
			<main>
				<Outlet/>
			</main>
			<Footer/>
		</>
	)
}

export { Layout }