import React from 'react'
import { __HOST__ } from '../const'
import DOMPurify from 'dompurify'

import {
  useProductsListQuery,
  useBlockQuery
} from '../store/api/water.api'
import { useSelector } from 'react-redux'

import Products from '../components/Products'
import Advantage from '../components/Advantage'
import CarouselMain from '../components/CarouselMain'
import TapeMain from '../components/TapeMain'
import Waiting from '../components/Waiting'
import ModalVideo from '../components/ModalVideo'

import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

const clearText = (str) => {
  let strip = document.createElement('div')
  strip.innerHTML = str
  return strip.innerText
}

const insertHtml = (html, attr = { element: 'div', classes: '', id: '' }) => {
  return (
    attr.element === 'div'
      ? <div className={ attr.classes } id={ attr.id } dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}></div>
      : <p className={ attr.classes } id={ attr.id } dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}></p>
  )
}

function PageHome() {
  const { t, i18n } = useTranslation()
  const lang = i18n.language

  const site = useSelector(state => state.settings.settings)

  const { isLoading: topLoading, isSuccess: topSuccess, data: topData} = useProductsListQuery({ show_on_main: 1, lang: lang, limit: 6 })
  const { isLoading: vhLoading, isSuccess: vhSuccess, data: vhData} = useBlockQuery({ alias: 'video-history', lang: lang })
  const { isLoading: aboutLoading, isSuccess: aboutSuccess, data: aboutData} = useBlockQuery({ alias: 'why-shoose-us', lang: lang })
  const { isLoading: bannerLoading, isSuccess: bannerSuccess, data: bannerData} = useBlockQuery({ alias: 'delivery', lang: lang })

  return (
    <>
      { typeof site === 'object' && <h1 className="seo">{ site.sitename }</h1> }
      <div className="area-content limited"><CarouselMain/></div>

      { topLoading ? <Waiting/> : topSuccess && topData.data.length > 0 &&
        <div className="area-content limited mt-4_0 mb-4_0">
          <div className="section-title tac ucase mb-0_5">{ t('popularProducts') }</div>
          <Products items={ topData.data }/>
        </div>
      }

      { aboutLoading ? <Waiting/> : aboutSuccess && aboutData.data.total > 0 &&
        <div className="area-content limited mt-4_0 mb-4_0">
          <div className="section-title tac ucase mb-0_5">{aboutData.data.name}</div>
          { insertHtml(aboutData.data.content, { classes: 'tac mb-4_0' }) }
          <div className="advantages">
            <div className="column-text">
              <Advantage key={0} item={aboutData.data.items[0]}/>
              <Advantage key={1} item={aboutData.data.items[1]}/>
            </div>
            <div className="column-image">
              <img src={__HOST__ + aboutData.data.picture} alt={aboutData.data.name}/>
            </div>
            <div className="column-text">
              <Advantage key={3} item={aboutData.data.items[2]}/>
              <Advantage key={4} item={aboutData.data.items[3]}/>
            </div>
          </div>
        </div>
      }

      { vhLoading ? <Waiting/> : vhSuccess && <ModalVideo data={vhData.data}/> }

      { bannerLoading ? <Waiting/> : bannerSuccess &&
      <div className="area-content limited delivery mb-4_0">
        <img className="img-bg" src={ __HOST__ + bannerData.data.pictures[0] } alt={ bannerData.data.name }/>
        <img className="img-fg" src={ __HOST__ + bannerData.data.pictures[1] } alt={ bannerData.data.name }/>
        <div className="banner">
          <div className="title tac mb-0_5">{ bannerData.data.name }</div>
          <p className='mt-0_5 tac font-small'>{ clearText(bannerData.data.content) }</p>
          <p className="tac"><Link to="/delivery" className="btn link"><b>{ t('detail') }</b></Link></p>
        </div>
      </div> }

      <TapeMain/>
    </>
  )
}

export default PageHome