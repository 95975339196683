import React from 'react'
import { useSelector } from 'react-redux'
// import { Link } from 'react-router-dom'

import Logo from './Logo'
import MenuItems from './MenuItems'
import FastNavigate from './FastNavigate'
import OneClick from './OneClick'
import CopyRight from './CopyRight'

import { useTranslation } from "react-i18next"

// const columns = [
//   {
//     col1: {
//       name: {
//         en: '',
//         ua: ''
//       }
//     },
//     col2: {
//       name: {
//         en: '',
//         ua: ''
//       }
//     },
//     col3: {
//       name: {
//         en: '',
//         ua: ''
//       }
//     },
//   }
// ]


function Footer() {
  const { t } = useTranslation()

  const contacts = useSelector(state => state.settings.settings)

  return (
    <footer>
        <div className="area-content limited">
          <div className="footer font-small pt-2_0 pb-2_0">
            <section>
              <div className="mt-1_0 mb-1_0 logo-column"><Logo/></div>
            </section>
            <section>
              <h4>{ t('footer.column1') }</h4>
              <ul>
                <MenuItems src={'footer'}/>
              </ul>
            </section>
            {/* <section>
              <h4>Our Services</h4>
              <ul>
                <li><a href="/">My Account</a></li>
                <li><a href="/">My Orders</a></li>
                <li><a href="/">My WishList</a></li>
                <li><a href="/">Returns</a></li>
              </ul>
            </section> */}
            {/* <section>
              <h4>Recent News</h4>
              <ul>
                <li><a href="/">News 1</a></li>
                <li><a href="/">News 2</a></li>
                <li><a href="/">News 3</a></li>
                <li><a href="/">News 4</a></li>
              </ul>
            </section> */}
            <section>
              <h4>{ t('footer.column2') }</h4>
              <ul>
                { contacts.phone_1?.length > 0 && <li key="phone_1"><a href={'tel:'+contacts.phone_1.replace(/\D/g,'')} className='ico' style={{'backgroundImage':'url(/dist/img/ico/icon-phone.svg)'}}>{contacts.phone_1}</a></li> }
                { contacts.phone_2?.length > 0 && <li key="phone_2"><a href={'tel:'+contacts.phone_2.replace(/\D/g,'')} className='ico'>{contacts.phone_2}</a></li> }
                { contacts.phone_3?.length > 0 && <li key="phone_3"><a href={'tel:'+contacts.phone_3.replace(/\D/g,'')} className='ico'>{contacts.phone_3}</a></li> }
                {/* <li><br/></li> */}
                { contacts.email_1?.length > 0 && <li key="email_1"><a href={'mailto:'+contacts.email_1} className='ico' style={{'backgroundImage':'url(/dist/img/ico/icon-email.svg)'}}>{contacts.email_1}</a></li> }
                { contacts.email_2?.length > 0 && <li key="email_2"><a href={'mailto:'+contacts.email_2} className='ico'>{contacts.email_1}</a></li> }
                { contacts.email_3?.length > 0 && <li key="email_3"><a href={'mailto:'+contacts.email_3} className='ico'>{contacts.email_1}</a></li> }
              </ul>
            </section>
            </div>
        </div>
        <CopyRight/>
        <FastNavigate/>
        <OneClick/>
    </footer>
  )
}

export default Footer