import React from 'react'
import { useTranslation } from "react-i18next"

function Search() {
  const searchRef = React.useRef()
  React.useEffect(() => {
    document.body.addEventListener('click', (e) => {
      !e.composedPath().includes(searchRef.current) && searchRef.current.classList.remove('__show')
    })
  }, [])
  const toggleSearch = (e) => { e.target.parentElement.classList.toggle('__show') }

  const { t } = useTranslation()

  return (
    <div className="search drop-down" ref={searchRef}>
      <button onClick={toggleSearch} className="drop-toggle">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
      </button>
      <form action="" className="drop-content">
        <fieldset className="search">
          <input type="search" placeholder={t('placeholder.search')} required/>
          <button>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
          </button>
        </fieldset>
      </form>
    </div>
  )
}

export default Search