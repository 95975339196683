import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { __HOST__ } from '../const'

export default function Logo(props) {
  const logo = useSelector(state => state.settings.settings.logo)
  const sitename = useSelector(state => state.settings.settings.sitename)
  return (
    logo
    &&
    <div className={'logo ' + props.src}>
      <Link to="/">
        <img src={__HOST__ + '/uploads/files/' + logo} alt="logo" />
      </Link>
      <span className='site-name'>{ sitename }</span>
    </div>
  )
}
