import React from 'react'
import MenuItems from './MenuItems'
import PhoneItems from './PhoneItems'

function Mainmenu() {
  const menuRef = React.useRef()
  React.useEffect(() => {
    document.body.addEventListener('click', (e) => {
      !e.composedPath().includes(menuRef.current) && menuRef.current.classList.remove('__show')
    })
  }, [])
  const toggleMenu = () => { document.querySelector('.main-menu.drop-down').classList.toggle('__show') }

  return (
    <div className="main-menu drop-down mobile-only" ref={menuRef}>
      <button onClick={ toggleMenu } className="drop-toggle main"><span></span><span></span><span></span></button>
      <section className="drop-content">
        <nav>
          <MenuItems src={ 'header' } toggleMenu={ toggleMenu }/>
          <PhoneItems/>
        </nav>
      </section>
    </div>
  )
}

export default Mainmenu