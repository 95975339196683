import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from "react-i18next"

const menu = [
  {
    caption: { en: 'Products', ua: 'Продукція' },
    icon: '/dist/img/ico/icon-products.svg',
    path: '/products'
  },
  {
    caption: { en: 'Delivery', ua: 'Доставка' },
    icon: '/dist/img/ico/icon-delivery.svg',
    path: '/delivery'
  },
  {
    caption: { en: 'Contacts', ua: 'Контакти' },
    icon: '/dist/img/ico/icon-contacts.svg',
    path: '/contacts'
  }
]
const menuFooter = [
  {
    caption: { en: 'Privacy policy', ua: 'Політика конфіденційності' },
    icon: '/dist/img/ico/icon-privacy.svg',
    path: '/privacy-policy'
  },
  {
    caption: { en: 'Terms of use', ua: 'Умови використання' },
    icon: '/dist/img/ico/icon-terms.svg',
    path: '/terms-of-use'
  }
]

function MenuItems(props) {
  const { i18n } = useTranslation()
  const lang = i18n.language
  return (
    <>
      { props.src === 'header'
        ? menu.map((item,n) => <li key={n}><NavLink to={item.path} style={{'backgroundImage':'url('+item.icon+')'}} className="ico mobile" onClick={ () => props.toggleMenu() } end>{item.caption[lang]}</NavLink></li>)
        : menu.map((item,n) => <li key={n}><NavLink to={item.path} style={{'backgroundImage':'url('+item.icon+')'}} className="ico" end>{item.caption[lang]}</NavLink></li>)
      }
      { props.src !== 'header' && menuFooter.map((item,n) => <li key={n}><NavLink to={item.path} style={{'backgroundImage':'url('+item.icon+')'}} className="ico" end>{item.caption[lang]}</NavLink></li>) }
    </>
  )
}

export default MenuItems