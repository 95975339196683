import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { updateCartItem, removeCartItem } from '../store/slices/cart.slice'
import { __HOST__ } from '../const'
import { useTranslation } from 'react-i18next'

export default function CartItem(props) {
	const cart = useSelector(state => state.cart.list)
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const pid = props.item.id
	const pcs = cart[pid]

	const dec = (e) => {
		if(pcs > props.item.min_cart_quantity) {
			dispatch(updateCartItem({ id: pid, pices: pcs - 1 }))
		}
	}
	const inc = (e) => {
		if(pcs < props.item.stock) {
			dispatch(updateCartItem({ id: pid, pices: pcs + 1 }))
		} else alert(t('errAddToCart'))
	}
	const set = (e) => {
		let value = parseInt(e.target.value)
		if(isNaN(value)) value = props.item.min_cart_quantity
		else if(value < props.item.min_cart_quantity) value = props.item.min_cart_quantity
		else if(value > props.item.stock) value = props.item.stock
		dispatch(updateCartItem({ id: pid, pices: value }))
	}
	const remove = (e) => {
		window.confirm(t('removeConfirm')) && dispatch(removeCartItem(pid))
	}

	return (
		<li className="cart-item">
			<img src={ __HOST__ + props.item.images[0].sm } alt={ props.item.name }/>
			<Link className="name" to={ '/products/' + pid }>{ props.item.name }</Link>
			<section>
				<div className="price">
					{ t('priceItem') }<div>{ props.item.price }<span>{ t('currency') }</span></div>
					{ t('priceItems' ) }<div>{ pcs * props.item.price }<span>{ t('currency') }</span></div>
				</div>
				<div className="pices">
					<button onClick={ dec }>
						<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
							<path fillRule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"/>
						</svg>
					</button>
					<input type="numeric" value={ pcs } onChange={ set }/>
					<button onClick={ inc }>
						<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
							<path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
						</svg>
					</button>
				</div>
			</section>
			<button className='cart-item-remove' onClick={ remove }>
				<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
					<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
					<path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
				</svg>
			</button>
		</li>
	)
}
