import React from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"

const umenu = {
	personal: [
		{
			caption: { en: 'Account', ua: 'Кабінет' },
			icon: 'dist/img/ico/icon-account.svg',
			path: '/personal'
		},
		{
			caption: { en: 'Orders', ua: 'Замовлення' },
			icon: 'dist/img/ico/icon-order.svg',
			path: '/personal/orders'
		},
		{
			caption: { en: 'Wishlist', ua: 'Обране' },
			icon: 'dist/img/ico/icon-star.svg',
			path: '/personal/wishlist'
		},
		{
			caption: { en: 'Logout', ua: 'Вийти' },
			icon: 'dist/img/ico/icon-logout.svg',
			path: '/'
		}
	],
	public: [
		{
			caption: { en: 'Login', ua: 'Увійти' },
			icon: 'dist/img/ico/icon-login.svg',
			path: '/login'
		},
		{
			caption: { en: 'Register', ua: 'Зареєструватися' },
			icon: 'dist/img/ico/icon-regs.svg',
			path: '/register'
		},
		// {
			// 	caption: { en: 'Reset password', ua: 'Скинути пароль' },
			// icon: 'dist/img/ico/icon-reset.svg',
		// 	path: '/reset-password'
		// }
	],
}

export default function UserAccount() {
	const { i18n } = useTranslation()
	// console.log(i18n)
	const lang = i18n.language

  const accountRef = React.useRef()
  React.useEffect(() => {
    document.body.addEventListener('click', (e) => {
      !e.composedPath().includes(accountRef.current) && accountRef.current.classList.remove('__show')
    })
  }, [])

  const toggleAccount = (e) => { e.target.parentElement.classList.toggle('__show') }
	return (
		<div className="user-account drop-down" ref={accountRef}>
			<button className="drop-toggle" onClick={toggleAccount}>
				<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
					<path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
					<path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
				</svg>
			</button>
			<ul className="drop-content">
			{ umenu.public.map((item,n) => <li key={n}><Link to={item.path} style={{'backgroundImage':'url('+item.icon+')'}} className='ico'>{item.caption[lang]}</Link></li>) }
			</ul>
		</div>
	)
}