import React from 'react'
import { Link } from 'react-router-dom'
import { useProductsCategoriesQuery } from '../store/api/water.api'
import { useTranslation } from 'react-i18next'

import Waiting from './Waiting'

const arr = [ 1, 2, 3, 4, 6, 8, 12 ]

export default function Filter(props) {
	// console.log(props.url)
	const { t, i18n } = useTranslation()
	const url = new URLSearchParams()
	for(let k in props.url) {
		if(k !== 'page' && k !== 'lang' && props.url[k] !== '') {
			url.set(k, props.url[k])
		}
	}

	const perPage = []
	arr.forEach(val => {
		if(val === 6) url.delete('limit')
		else url.set('limit', val)
		perPage.push({
			val: val,
			query: '?' + url.toString(),
			classes: val === props.limit ? 'selected' : ''
		})
	})
	if(props.url.limit === 6 ) url.delete('limit')
	else url.set('limit', props.url.limit)

	const { isLoading, isSuccess, data } = useProductsCategoriesQuery({ lang: i18n.language })
	const categories = []
	if(isSuccess) {
		url.delete('fkCategory')
		categories.push({
			key: '',
			query: '?' + url.toString(),
			name: t('filter.all'),
			classes: props.url.fkCategory === '' ? 'radio checked' : 'radio'
		})
		data.data.forEach(cat => {
			url.set('fkCategory', cat.id)
			categories.push({
				key: cat.id,
				query: '?' + url.toString(),
				name: cat.name,
				classes: cat.id === props.url.fkCategory ? 'radio checked' : 'radio'
			})
		})
		if(props.url.fkCategory === '' ) url.delete('fkCategory')
		else  url.set('fkCategory', props.url.fkCategory)
	}

	const stock = {}
	if(props.url.availableInStock === '') {
		url.set('availableInStock', 1)
		stock.val = 1
		stock.classes = 'checkbox'
	} else {
		url.delete('availableInStock')
		stock.val = ''
		stock.classes = 'checkbox checked'
	}
	stock.query = '?' + url.toString()

	/*
	const promotion = {}
	url.delete('fkCategory')
	url.delete('availableInStock')
	if(props.url.hasPromotions === '') {
		url.set('hasPromotions', 1)
		promotion.val = 1
		promotion.classes = 'checkbox'
	} else {
		url.delete('hasPromotions')
		promotion.val = ''
		promotion.classes = 'checkbox checked'
	}
	const avail = props.url.hasPromotions === '' ? '' : ' notavail'
	promotion.query = '?' + url.toString()
	*/
	const avail = ''

	const pagesRef = React.useRef()
	const [ dropLimit, setDropLimit ] = React.useState(false)

	React.useEffect(() => {
		dropLimit ? pagesRef.current.classList.add('__show') : pagesRef.current.classList.remove('__show')
  }, [ dropLimit ])

	/**
	 * TODO: disable category filter when promotion checked
	 */
	return (
		<div className="filter">
			<button className="btn filter-hide" onClick={ () => props.showFilter() }>
				<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
					<path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
				</svg>
			</button>
			<hr className='mt-1_0 mb-1_0'/>
			<div className="per-page drop-down mb-1_0" ref={ pagesRef } onBlur={ () => setTimeout(() => setDropLimit(false), 250) }>
				<button className="drop-toggle" onClick={ () => setDropLimit(!dropLimit) }>{ t('filter.perPage') + ': ' }<span>{ props.url.limit }</span></button>
				<ul className="drop-content">
					{ perPage.map(item =>
						<li key={ item.val }>
							<Link
								className={ item.classes }
								to={ item.query }
								onClick={ () => { props.setLimit(item.val) }}
							>{ item.val }</Link>
						</li>
					)}
				</ul>
			</div>
			{ isLoading && <Waiting/> }
			{ categories.length > 0 &&
				<div className={ 'categories-list mb-2_0' + avail }>
					<h5>{ t('filter.categories') }</h5>
					<ul>
						{ categories.map(item =>
							<li key={ item.key }>
								<Link to={ item.query } onClick={ () => props.setCategory(item.key) } className={ item.classes }><span>{ item.name }</span></Link>
							</li>
						)}
					</ul>
				</div>
			}
			<hr className='mb-1_0'/>
			<div className={ 'mb-1_0' + avail }><Link to={ stock.query } onClick={ () => props.setStock(stock.val) } className={ stock.classes }><span>{ t('filter.stock') }</span></Link></div>
			{/* <div className='mb-1_0'><Link to={ promotion.query } onClick={ () => props.setPromotion(promotion.val) } className={ promotion.classes }><span>{ t('filter.promotion') }</span></Link></div> */}
			<hr className='mb-1_0'/>
			<Link className="btn more" to="" onClick={ () => props.reset() }>
				<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
					<path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
					<path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
				</svg>
				{ t('filter.reset') }
			</Link>
		</div>
	)
}
