import React from 'react'
import HeaderCart from './HeaderCart'
import Search from './Search'
import Mainmenu from './Mainmenu'
import Logo from './Logo'
import LangSelector from './LangSelector'
import UserAccount from './UserAccount'
import ScoreBoard from './ScoreBoard'

function Header() {
  return (
    <>
      <header>
        <div className="area-content limited">
          <div className="inner">
            <Logo src="header"/>
            <Mainmenu/>
            <Search/>
            <HeaderCart/>
            <LangSelector/>
            <UserAccount/>
          </div>
        </div>
        <div className="area-content limited"><ScoreBoard/></div>
      </header>
    </>
	)
}

export default Header