import React from 'react'
// import { Link } from 'react-router-dom'
import { __HOST__ } from '../const'
import DOMPurify from 'dompurify'

const insertHtml = (html, attr = { element: 'div', classes: '', id: '' }) => {
  return (
    attr.element === 'div'
      ? <div className={ attr.classes } id={ attr.id } dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}></div>
      : <p className={ attr.classes } id={ attr.id } dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}></p>
  )
}

export default function Advantage(props) {
	return (
		<div className="advantage" key={ props.item.id }>
			<img src={ __HOST__ + props.item.picture } alt={ props.item.name }/>
			<h5>{ props.item.name }</h5>
			{ insertHtml(props.item.content) }
			{/* <Link to={props.item.link} className="btn">Read More</Link> */}
		</div>
	)
}