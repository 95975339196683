import React from "react"
import { useSelector, useDispatch } from 'react-redux'
import { useProductsListQuery } from '../store/api/water.api'
import { initCart } from '../store/slices/cart.slice'
import { useTranslation } from "react-i18next"

export default function GetCartInfo() {
	const dispatch = useDispatch()
	const { i18n } = useTranslation()
	const lang = i18n.language
	const cart = useSelector(state => state.cart)
	const ids = Object.keys(cart.list)
	const query = ids.length ? ids.join(',') : '0'
	const { isSuccess, data } = useProductsListQuery({ lang: lang, ids: query })
	React.useEffect(() => { isSuccess && dispatch(initCart(data.data)) }, [data])
}