import React from 'react'
import { Link } from 'react-router-dom'
import { __HOST__ } from '../const'
import { useTranslation } from 'react-i18next'
import { useSelector,  useDispatch } from 'react-redux'
import { addCartItem, updateCartItem } from '../store/slices/cart.slice'

/*
const clearText = (str, clip = 200) => {
  let strip = document.createElement('div')
  strip.innerHTML = str
  return strip.innerText.substring(0, clip)
}
*/
const oneClickBuy = (e) => {
  let oneClick = document.querySelector('.modal-window.modal-one-click'), fset = null, html = ''
  if(oneClick) {
    fset = oneClick.querySelector('fieldset')
    html += `<input type="hidden" name="form" value="oneClickProd"/>`
    html += `<input type="hidden" name="prod" value="${e.target.getAttribute('pid')}"/>`
    html += `<input type="hidden" name="cart" value="${e.target.getAttribute('items').replace(/"/g,'\\"')}"/>`
    fset.innerHTML = html
    oneClick.setAttribute('open', '')
    document.body.style.overflow = 'hidden'
  }
}

function makeString(obj) {
  let arr = []
  for(let i in obj) arr.push(i + ':' + obj[i])
  return arr.toString()
}

function Products(props) {
  // console.log(props)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const cart = useSelector(state => state.cart)

  const addToCart = (e) => {
    let id = parseInt(e.target.getAttribute('pid')), pcs = typeof cart.list[id] === 'number' ? cart.list[id] : 0
    document.body.classList.add('waiting')
    fetch('https://wbtest.marat.ua/api/v1/products/get?fkProduct=' + id, { headers: {'Auth-Token': 'o3ta6c9OqMzjzf9D'} }).then(response => response.json()).then(result => {
      if(!result.data.error) {
        if(pcs === 0 ) {
          dispatch(addCartItem(result))
        } else {
          if(result.data.stock > pcs) dispatch(updateCartItem({ id: id, pices: pcs + 1 }))
          else alert(t('errAddToCart'))
        }
        document.body.style.overflow = 'hidden'
        document.querySelector('.modal-window.modal-cart').setAttribute('open','')
      }
      document.body.classList.remove('waiting')
    }).catch(err => {
      console.warn(err)
      document.body.classList.remove('waiting')
    })
  }

  return (
    <div className="products2 mb-2_0">
      { props.items.length > 0 ?
        props.items.map(item => (
        <div key={'pid_' + item.id} className="product" category={item.category.id}>
          <Link to={'/products/' + item.id}><img className="img" src={__HOST__ + item.images[0].md} alt={item.name}/></Link>
          <div className="star-rating" style={{'--rating': item.rating*100+'0%'}}></div>
          <div className="name"><Link className="e--link" to={'/products/' + item.id}>{ item.name }</Link></div>
          <div className="price">
            <span className="current">{ item.price }<i>{ t('currency') }</i></span>
            { item.price_old && <span className="old">{ item.price_old }<i>{ t('currency') }</i></span> }
          </div>
          {/* <div className="intro">{ item.description }</div> */}
          <div className='btn-block'>
            <button className="btn sm" disabled={ item.stock < item.min_cart_quantity } pid={ item.id } cart={ cart.list[item.id] } onClick={ addToCart }><img src="/dist/img/ico/basket.svg" alt="add to cart"/>{ t('cartAdd') }</button>
            <button className="btn sm" disabled={ item.stock < item.min_cart_quantity } pid={ item.id } items={ makeString(cart.list) } onClick={ oneClickBuy }><img src="/dist/img/ico/icon-oneclick.svg" alt="buy in 1 click"/>{ t('cart1Click') }</button>
          </div>
        </div>
      )) : (
        <div className="nothing">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.498 3.498 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.498 4.498 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"/>
          </svg>
          <span className='ucase'>{ t('nothing') }</span>
        </div>
      )}
    </div>
  )
}

export default Products