import React from 'react'
import { __HOST__ } from '../const'
import DOMPurify from 'dompurify'
import { useTranslation } from 'react-i18next'

import { useBlockQuery } from '../store/api/water.api'

import Waiting from '../components/Waiting'
// import { Link } from 'react-router-dom'

/*
const clearText = (str) => {
  let strip = document.createElement('div')
  strip.innerHTML = str
  return strip.innerText
}
*/

const insertHtml = (html, attr = { element: 'div', classes: '', id: '' }) => {
  return (
    attr.element === 'div'
      ? <div className={ attr.classes } id={ attr.id } dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}></div>
      : <p className={ attr.classes } id={ attr.id } dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}></p>
  )
}

function PageDelivery() {
  const { i18n } = useTranslation()
  const lang = i18n.language

  const { isLoading, isSuccess, data} = useBlockQuery({ alias: 'delivery', lang: lang })

  return (
    <>
      { isLoading ? <Waiting/> : isSuccess &&
        <div className="area-content limited mt-4_0 mb-4_0">
          <h1 className="section-title tac ucase mb-1_0">{ data.data.name }</h1>
          <img className="img-bg" src={ __HOST__ + data.data.pictures[0] } alt={ data.data.name }/>
          <hr className="mt-2_0 mb-2_0"/>
          { insertHtml(data.data.content) }
        </div>
      }
    </>
  )
}

export default PageDelivery