import React from 'react'
import { __HOST__ } from '../const'
import DOMPurify from 'dompurify'

export default function ModalVideo(props) {
	const open = () => {
		let modal = document.getElementById('modal-video')
		let video = props.data.link.replace(/^(.*)([\\?\\&]v=)([^\\&\\#]*)(.*)$/gm, `$3`)
		modal.insertAdjacentHTML('afterbegin', `<iframe src="https://www.youtube.com/embed/${video}?autoplay=1" title="${props.data.name}" frameBorder="0" allowFullScreen></iframe>`)
		modal.showModal()
		document.body.style.overflow = 'hidden'
	}
	const close = () => {
		let modal = document.getElementById('modal-video')
		modal.children[0].remove()
		modal.close()
		document.body.style.overflow = ''
	}
	const insertHtml = (html, attr = { classes: '', id: '' }) => {
		return (
			<div className={attr.classes} id={attr.id} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}></div>
		)
	}

	return (
		<div className="area-content limited mt-4_0 mb-4_0">
			<div className="section-title tac ucase mb-0_5">{props.data.name}</div>
			{ insertHtml(props.data.content, { classes: 'tac mb-2_0', id: '' }) }
			<div className="video">
				<img src={__HOST__+props.data.picture} alt={props.data.name}/>
				<button className="play" onClick={open}>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
						<path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
					</svg>
				</button>
			</div>
			<dialog id="modal-video" onClick={close}>
				<button className="close">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
  					<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
					</svg>
				</button>
			</dialog>
		</div>
	)
}

// lLGLUSzzuWU