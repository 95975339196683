import React from 'react'
import { Link } from 'react-router-dom'
import CartItem from './CartItem'
import { useTranslation } from "react-i18next"
import { useSelector } from 'react-redux'

function makeString(obj) {
  let arr = []
  for(let i in obj) arr.push(i + ':' + obj[i])
  return arr.toString()
}

const oneClickBuy = (e) => {
  let oneClick = document.querySelector('.modal-window.modal-one-click'), fset = null, html = ''
  if(oneClick) {
    fset = oneClick.querySelector('fieldset')
    html += `<input type="hidden" name="form" value="oneClickCart"/>`
    html += `<input type="hidden" name="cart" value="${e.target.getAttribute('items').replace(/"/g,'\\"')}"/>`
    fset.innerHTML = html
    oneClick.setAttribute('open', '')
    document.body.style.overflow = 'hidden'
  }
}

export default function Cart() {
	const { t } = useTranslation()
	const cart = useSelector(state => state.cart)

	const modalRef = React.useRef()
	const close = (e) => {
		e.stopPropagation()
		modalRef.current.removeAttribute('open')
		document.body.style.overflow = ''
	}

	return (
		<div className="modal-window modal-cart" onClick={ close } ref={ modalRef }>
			<div className="modal-wrapper" onClick={ event => event.stopPropagation() }>
				<div className="cart-header">
					<div className='cart-caption'>{ t('cartHeader') }</div>
					<button className="cart-close" onClick={ close }>
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
							<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
						</svg>
					</button>
					{ cart.products.length > 0 &&
						<>
							<div className="total-value">{ t('totalCartPrice') }: <b>{ cart.total }</b>&nbsp;<small>{ t('currency') }</small></div>
							<div className="buy-controls">
								<Link className="order btn sm more" style={{ 'color': '#fff' }} to={ '/order' } onClick={ close }>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" viewBox="0 0 16 16">
										<path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"/>
										<path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
									</svg>
									{ t('makeOrder') }
								</Link>
								<button className="order btn sm more" style={{ 'color': '#fff' }} items={ makeString(cart.list) } onClick={ oneClickBuy }>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" viewBox="0 0 16 16">
										<path d="M6.75 1a.75.75 0 0 1 .75.75V8a.5.5 0 0 0 1 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 0 0 1 0V6.435l.106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 1 0 1 0v-1h.342a1 1 0 0 1 .995 1.1l-.271 2.715a2.5 2.5 0 0 1-.317.991l-1.395 2.442a.5.5 0 0 1-.434.252H6.118a.5.5 0 0 1-.447-.276l-1.232-2.465-2.512-4.185a.517.517 0 0 1 .809-.631l2.41 2.41A.5.5 0 0 0 6 9.5V1.75A.75.75 0 0 1 6.75 1zM8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v6.543L3.443 6.736A1.517 1.517 0 0 0 1.07 8.588l2.491 4.153 1.215 2.43A1.5 1.5 0 0 0 6.118 16h6.302a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5.114 5.114 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.632 2.632 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046l-.048.002zm2.094 2.025z"/>
									</svg>
									{ t('oneClick') }
								</button>
							</div>
						</>
					}
				</div>
				<div className="cart-content">
					{ cart.products.length > 0
						? <ul>{ cart.products.map(product => <CartItem key={ product.id } item={ product }/>) }</ul>
						: <p className='mt-1_0 mb-1_0' style={{ lineHeight: '2', padding: '0 1em' }}>{ t('emptyCart') }</p>
					}
				</div>
			</div>
		</div>
	)
}