import React from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
// import { useCookies } from 'react-cookie'

import { Layout } from './components/Layout'
import PageHome from './pages/PageHome'
import PageDelivery from './pages/PageDelivery'
import PageContacts from './pages/PageContacts'
import PageProducts from './pages/PageProducts'
import PageDetails from './pages/PageDetails'
import PageOrder from './pages/PageOrder'
import Page404 from './pages/Page404'

import { useDispatch } from 'react-redux'
import { useSettingsQuery } from './store/api/water.api'
import { setSettings } from './store/slices/settings.slice'

function App() {
  const dispatch = useDispatch()
  const { isSuccess, data } = useSettingsQuery()
  React.useEffect(() => {
    isSuccess && dispatch(setSettings(data.data))
  }, [ data, dispatch, isSuccess ])

  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={ <Layout/> }>
            <Route index element={ <PageHome/> }/>
            <Route path="products" element={ <PageProducts/> }/>
            <Route path="products/:id" element={ <PageDetails/> }/>
            <Route path="delivery" element={ <PageDelivery/> }/>
            <Route path="order" element={ <PageOrder/> }/>
            <Route path="contacts" element={ <PageContacts/> }/>
            <Route path="*" element={ <Page404/> }/>
          </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
