import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: [ 'ua', 'en' ],
  saveMissing: true,
  debug: false,
  detection: {
    order: [ 'localStorage', 'cookie' ],
    cache: [ 'cookie' ],
    lookupQuerystring: 'lang',
    lookupSessionStorage: 'i18nLang',
    lookupLocalStorage: 'i18nLang',
    lookupCookie: 'i18n'
  },
  interpolation: {
    escapeValue: false
  }
})

export default i18n