import React from 'react'
import { useTranslation } from "react-i18next"
import { useLocation } from 'react-router-dom'

const telInput = {
	keys: ['0','1','2','3','4','5','6','7','8','9'],
	ctrl: ['Enter','Tab','Escape', 'Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Home', 'End'],
	key: null,
	__placeholder(tel, ph = null) {
		let response = true, val = tel.getAttribute('placeholder')
		if(ph) tel.value = ''
		else {
			ph = val
			if(!ph || ph.length === 0) ph = '+38(0__)___-__-__'
		}
		ph = '+' + ph.replace(/\+/g,'')
		let pt = '', mask = ph.slice(-1), mask_added = false
		if(!isNaN(parseInt(mask))) response = false
		else {
			for(let i = 0; i < ph.length; i++) {
				switch(ph[i]) {
					case mask:
						mask_added = true
						pt += '\\d'
						break
					case '+':
						pt += '\\+'
						break
					case '*':
						pt += '\\*'
						break
					case '(':
						pt += '\\('
						break
					case ')':
						pt += '\\)'
						break
					default:
						if(!isNaN(parseInt(ph[i]))) {
							if(mask_added) response = false
							else pt += ph[i]
						} else pt += ph[i]
				}
			}
		}
		if(response) {
			tel.setAttribute('placeholder', ph)
			tel.setAttribute('pattern', pt)
			tel.value = ph
		} else {
			tel.removeAttribute('placeholder')
			tel.removeAttribute('pattern')
			console.warn('Invalid format of placeholder value')
		}
		return response
	},
	keydown(e) {
		telInput.key = e.key
		if(telInput.ctrl.indexOf(e.key) > -1) {
			if(e.key === 'Escape') {
				e.target.value = e.target.placeholder
				telInput.__next(e.target)
			}
			if(e.key === 'ArrowLeft' || e.key === 'Backspace') {
				let pos = e.target.selectionStart,
					placeholder = e.target.getAttribute('placeholder'),
					pref = placeholder.replace(/\D/g,'')
				if(e.target.value.substring(0,pos-1).replace(/\D/g,'').length < pref.length) e.preventDefault()
			} else if(e.key === 'Delete') {
				let pos = e.target.selectionStart,
					right = e.target.value.substring(pos),
					rnum = right.replace(/\D/g,'')
				if(rnum.length) {
					pos += right.indexOf(rnum[0])
					e.target.setSelectionRange(pos,pos)
				}
			} else if(e.key === 'Home' || e.key === 'End') {
				e.preventDefault()
				telInput.__next(e.target)
			}
		} else {
			if(telInput.keys.indexOf(e.key) < 0) e.preventDefault()
			else {
				let placeholder = e.target.getAttribute('placeholder'),
					mask = placeholder.slice(-1),
					re = new RegExp(`[^\\d${mask}]`,'g'),
					pos = e.target.selectionStart,
					pref = placeholder.replace(/\D/g,''),
					first = telInput.__first(placeholder, pref)
				if(pos < first) e.target.setSelectionRange(first,first)
				if(e.target.value.replace(/\D/g,'').length < placeholder.replace(re,'').length) e.target.style.caretColor = 'transparent'
				else e.preventDefault()
			}
		}
	},
	__first(ph, pref) {
		for(let i = 0, n = 0, d; i < ph.length; i++) {
			d = ph[i]
			if(!isNaN(parseInt(d)) && ++n >= pref.length) return ++i
		}
		return -1
	},
	input(e) {
		let val = e.target.value,
			num = val.replace(/\D/g,''),
			pos = e.target.selectionStart,
			right = e.target.value.substring(pos),
			rnum = right.replace(/\D/g,''),
			pref = e.target.getAttribute('placeholder').replace(/\D/g,'')
		e.target.value = telInput.__format(e.target, num.slice(pref.length))
		if(telInput.keys.indexOf(telInput.key) > -1) {
			if(rnum.length) {
				pos += right.indexOf(rnum[0])
				e.target.setSelectionRange(pos,pos)
			} else telInput.__next(e.target)
		} else if(telInput.key === 'Backspace' || telInput.key === 'Delete') {
			e.target.setSelectionRange(pos,pos)
		}
		setTimeout(() => { e.target.style.caretColor = 'initial' }, 50)
	},
	__format(tel, num) {
		let mask = tel.getAttribute('placeholder').slice(-1), ret = ''
		for(let i = 0, ch, n = 0; i < tel.placeholder.length; i++) {
			ch = tel.placeholder[i]
			if(ch === mask && n < num.length) ret += num[n++]
			else ret += ch
		}
		return ret
	},
	__next(tel) {
		let mask = tel.getAttribute('placeholder').slice(-1)
		let pos = tel.value.indexOf(mask)
		setTimeout(() => tel.setSelectionRange(pos, pos), 50)
	},
	focus(e) {
		let num = e.target.value.replace(/\D/g,''),
			ph = e.target.getAttribute('placeholder')
		if(!num.length) {
			if(ph) e.target.value = ph
			else telInput.__placeholder(e.target, '+38(0__)___-__-__')
		}
		// else e.target.value = telInput.__format(e.target, num)
		telInput.__next(e.target)
	},
	blur(e) {
		if(e.target.value === e.target.getAttribute('placeholder')) e.target.value = ''
	}
}

const sendForm = (e) => {
	e.preventDefault()
	let data = new FormData(e.target)
	data.append('time', Date.now())
	for(let item of data.entries()) console.log(item)
}

export default function OneClick() {
	const { t, i18n } = useTranslation()
	const location = useLocation()

	const modalRef = React.useRef()
	const close = (e) => {
		e.stopPropagation()
		modalRef.current.removeAttribute('open')
		document.body.style.overflow = ''
	}
	const msgRef = React.useRef()

	return (
		<div className="modal-window modal-one-click" onClick={ close } ref={ modalRef }>
			<div className="modal-wrapper" onClick={ event => event.stopPropagation() }>
				<div className="one-click-header">
					<div className='one-click-caption'>{ t('form.1click') }</div>
					<button className="one-click-close" onClick={ close }>
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
							<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
						</svg>
					</button>
				</div>
				<form onSubmit={ sendForm }>
					<div className="form-msg-area" ref={ msgRef }></div>
					<input type="hidden" name="lang" value={ i18n.language } />
					<input type="hidden" name="page" value={ location.pathname } />
					<fieldset></fieldset>
					<div className="one-click-form">
						<label>
							{ t('form.name') }
							<input type="text" name="user" />
						</label>
						<label>
							{ t('form.phone') }
							<input
								type="tel"
								name="phone"
								onKeyDown={ (event) => telInput.keydown(event) }
								onInput={ (event) => telInput.input(event) }
								onFocus={ (event) => telInput.focus(event) }
								onBlur={ (event) => telInput.blur(event) }
								placeholder="+38(0__)___-__-__"
							/>
						</label>
						<p className='tar mg-0'>
							<button className="btn more">{ t('form.submit') }</button>
						</p>
					</div>
				</form>
			</div>
		</div>
	)
}