import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNewsListQuery } from '../store/api/water.api'
import { setScoreboard } from '../store/slices/news.slice'

const clearText = (str) => {
  let strip = document.createElement('div')
  strip.innerHTML = str
  return strip.innerText
}
const setAnimationTime = (e) => {
  e.target.style = `animation-play-state: paused; opacity: 0`
  setTimeout(() => {
    let t = window.innerWidth > 1000 ? window.innerWidth * e.target.scrollWidth * .00002 : 20
    e.target.style = `--scroll-time: ${t}s`
  }, 100)
}

export default function Important() {
  const { i18n } = useTranslation()
  const lang = i18n.language

  const [ board, setBoard ] = React.useState([])

  const dispatch = useDispatch()
  const { isSuccess: successBoard, data: dataBoard } = useNewsListQuery({ lang: lang, show_on_main: 1 })
  React.useEffect(() => {
    if(successBoard) {
      setBoard(dataBoard.data)
      dispatch(setScoreboard(dataBoard.data))
    }
  }, [dataBoard])

	return (
    board.length > 0 &&
    <div className="marquee on-header">
        <div className="marquee-track" onChange={ setAnimationTime }>
          {board.map(m => (
            <p key={ m.id }><b>{ m.name }</b>{ clearText(m.text) }</p>
          ))}
        </div>
    </div>
	)
}
