import React from 'react'
import { useSelector } from 'react-redux'

// const list = [ '+38(050)123-45-67', '+38(050)123-45-67', '+38(050)123-45-67' ]
// const list = [ '+38(050)123-45-67', '+38(050)123-45-67' ]
// const list = [ '+38(050)123-45-67' ]
// const list = []
// const item1st = list.length ? list.pop() : []
const bg = 'url(/dist/img/ico/icon-phone.svg)'

function Phones() {
  const settings = useSelector(state => state.settings.settings)
  const phoneRef = React.useRef()
  React.useEffect(() => {
    document.body.addEventListener('click', (e) => {
      phoneRef.current && !e.composedPath().includes(phoneRef.current) && phoneRef.current.classList.remove('__show')
    })
  }, [])
  const togglePhone = (e) => { e.target.parentElement.classList.toggle('__show') }

  if(settings.hasOwnProperty('phone_1')) {
    let phone = settings.phone_1.length ? settings.phone_1 : null, rest = []
    settings.phone_2.length && rest.push(settings.phone_2)
    settings.phone_3.length && rest.push(settings.phone_3)
    if(rest.length) {
      return (
        <li className="phones drop-down desktop-only" ref={phoneRef}>
          <button onClick={togglePhone} className="drop-toggle">
            <a href={'tel:'+ phone.replace(/\D/g,'')} className="ico mobile" style={{'backgroundImage':bg}}>{phone}</a>
          </button>
          <ul className='drop-content'>{ rest.map((item,n) => <li key={n}><a href={'tel:'+ item.replace(/\D/g,'')} className='ico mobile'>{item}</a></li>) }</ul>
        </li>
      )
    } else if(phone.length) {
      return (
        <li><a href={'tel:'+ phone.replace(/\D/g,'')} className="ico" style={{ 'backgroundImage': bg }}>{phone}</a></li>
      )
    }
  }
}

export default Phones