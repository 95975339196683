import React from 'react'
import { __HOST__ } from '../const'
import DOMPurify from 'dompurify'
import { useTranslation } from 'react-i18next'

// import { useBlockQuery } from '../store/api/water.api'
import { useSelector } from 'react-redux'

// import Waiting from '../components/Waiting'
// import { Link } from 'react-router-dom'

/*
const clearText = (str) => {
  let strip = document.createElement('div')
  strip.innerHTML = str
  return strip.innerText
}
*/

const insertHtml = (html, attr = { element: 'div', classes: '', id: '' }) => {
  return (
    attr.element === 'div'
      ? <div className={ attr.classes } id={ attr.id } dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}></div>
      : <p className={ attr.classes } id={ attr.id } dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}></p>
  )
}

function PageContacts() {
	const contacts = useSelector(state => state.settings.settings)
  const { t, i18n } = useTranslation()
  const lang = i18n.language

  // const { isLoading, isSuccess, data} = useBlockQuery({ alias: 'delivery', lang: lang })

  return (
    <>
			{ contacts.toString().length &&
				<div className="area-content limited mt-4_0 mb-4_0">
					<h1 className="section-title tac ucase mb-1_0">{ t('contacts') }</h1>
					<div className="contacts-layout">
						<img className="img-bg" src={ __HOST__ + '/uploads/files/' + contacts.logo } alt="logo" width="200"/>
						<h3>{ contacts.sitename }</h3>
						<div className="contacts-section">
							<h5>{ lang === 'en' ? 'Phones' : 'Телефони' }</h5>
							{ contacts.phone_1.length > 0 && insertHtml(contacts.phone_1) }
							{ contacts.phone_2.length > 0 && insertHtml(contacts.phone_2) }
							{ contacts.phone_3.length > 0 && insertHtml(contacts.phone_3) }
						</div>
						<div className="contacts-section">
							<h5>E-mail</h5>
							{ contacts.email_1.length > 0 && insertHtml(contacts.email_1) }
							{ contacts.email_2.length > 0 && insertHtml(contacts.email_2) }
							{ contacts.email_3.length > 0 && insertHtml(contacts.email_3) }
						</div>
						<div className="contacts-section">
							<h5>{ lang === 'en' ? 'We in social networks' : 'Ми в соціальних мережах' }</h5>
							{ contacts.facebook.length > 0 && insertHtml(`<b>Facebook</b>: <a href="${contacts.facebook}" rel="nofollow" target="_blank">${contacts.facebook}</a>`) }
							{ contacts.twitter.length > 0 && insertHtml(`<b>Twitter</b>: <a href="${contacts.twitter}" rel="nofollow" target="_blank">${contacts.twitter}</a>`) }
							{ contacts.instagram.length > 0 && insertHtml(`<b>Instagram</b>: <a href="${contacts.instagram}" rel="nofollow" target="_blank">${contacts.instagram}</a>`) }
						</div>
					</div>
					<hr className="mt-2_0 mb-2_0"/>
				</div>
			}
    </>
  )
}

export default PageContacts