import React from 'react'
// import { __HOST__ } from '../const'
// import DOMPurify from 'dompurify'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

// import { useBlockQuery } from '../store/api/water.api'

import Waiting from '../components/Waiting'
import CartItem from '../components/CartItem'

/*
const clearText = (str) => {
  let strip = document.createElement('div')
  strip.innerHTML = str
  return strip.innerText
}
*/

// const insertHtml = (html, attr = { element: 'div', classes: '', id: '' }) => {
//   return (
//     attr.element === 'div'
//       ? <div className={ attr.classes } id={ attr.id } dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}></div>
//       : <p className={ attr.classes } id={ attr.id } dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}></p>
//   )
// }

function PageOrder() {
  const { t, i18n } = useTranslation()
	const cart = useSelector(state => state.cart)
	const [ ready, setReady ] = React.useState(false)
	React.useEffect(() => { setReady(true) }, [cart])

  // const { isLoading, isSuccess, data} = useBlockQuery({ alias: 'delivery', lang: i18n.language })

	function sendOrder(e) {
		e.preventDefault()
	}

  return (
		<div className="area-content limited mt-2_0 mb-2_0">
			{ !ready && <Waiting/> }
			{ ready && cart.products?.length > 0 ? (
				<>
					<h1 className="section-title tac ucase mg-0">Order</h1>
					<hr className="mt-1_0 mb-2_0"/>
					<div className="order-layout mb-4_0">
						<div className="order-data">
							<form onSubmit={ sendOrder }>
								<input type="hidden" name="lang" value={ i18n.language } />
								<input type="hidden" name="form" value="order" />
								<div className="order-form-layout">
									<label>
										field 1
										<input type="text" name="user" />
									</label>
									<label>
										field 2
										<input type="tel" name="phone" />
									</label>
									<label>
										field 3
										<input type="text" name="user" />
									</label>
									<label>
										field 4
										<input type="tel" name="phone" />
									</label>
									<label>
										field 5
										<input type="text" name="user" />
									</label>
									<label>
										field 6
										<input type="tel" name="phone" />
									</label>
								</div>
								<label className='mt-1_0 mb-1_0'>
									Comment
									<textarea name="" cols="30" rows="5"></textarea>
								</label>
								<p className=''>
									<button className="btn more">{ t('form.submit') }</button>
								</p>
							</form>
						</div>
						<div className='order-cart'>
							<div className="total-value">{ t('totalCartPrice') }: <b>{ cart.total }</b>&nbsp;<small>{ t('currency') }</small></div>
							<hr className="mt-1_0 mb-1_0" />
							<ul>{ cart.products.map(product => <CartItem key={ product.id } item={ product }/>) }</ul>
						</div>
					</div>
				</>
			) : (
				<>
					<div className="tac ucase font-bold mb-2_0">{ t('emptyCart') }</div>
					<hr className="mt-1_0 mb-1_0"/>
					<div className="half-width">
						<p className="tal mb-1_0">&larr;&nbsp;<Link to="/products">{ t('startBuy') }</Link></p>
						<p className="tal">&larr;&nbsp;<button className="back" onClick={ () => window.history.back() }>{ t('page404.back') }</button></p>
					</div>
				</>
			)}
		</div>
  )
}

export default PageOrder