import { createSlice } from "@reduxjs/toolkit"

function getLocalCart() {
	let init = window.localStorage.getItem('cart')
	if(!init || init === '') return {}
	let ret = {}
	try { ret = JSON.parse(init) }
	catch(err) { ret = {} }
	return ret
}

const initialState = {
	list: getLocalCart(),
	products: [],
	pices: 0,
	total: 0
}

const cartSlice = createSlice({
  name: "cart",
	initialState,
  reducers: {
		addCartItem(state, action) {
			// console.log('ADD TO CART', action.payload)
			let pcs = action.payload.data.min_cart_quantity
			state.products.push(action.payload.data)
			state.pices += pcs
			state.total += parseFloat(action.payload.data.price) * pcs
			state.list[action.payload.data.id] = pcs
			window.localStorage.setItem('cart', JSON.stringify(state.list))
		},
    removeCartItem(state, action) {
			// console.log('REMOVE FROM CART', action.payload)
			let id = action.payload, qty = 0, sum = 0, uprod = []
			delete state.list[id]
			for(let i = 0, prod; i < state.products.length; i++) {
				prod = state.products[i]
				if(prod.id !== id) {
					qty += state.list[prod.id]
					sum += prod.price * state.list[prod.id]
					uprod.push(prod)
				}
			}
			state.products = uprod
			state.pices = qty
			state.total = sum
			window.localStorage.setItem('cart', JSON.stringify(state.list))
		},
		updateCartItem(state, action) {
			// console.log('UPDATE CART ITEM', action.payload)
			let id = action.payload.id, pcs = action.payload.pices
			state.list[id] = pcs
			let qty = 0, sum = 0
			state.products.forEach(prod => {
				qty += state.list[prod.id]
				sum += prod.price * state.list[prod.id]
			})
			state.pices = qty
			state.total = sum
			window.localStorage.setItem('cart', JSON.stringify(state.list))
		},
		initCart(state, action) {
			// console.log('INIT CART', action.payload)
      let refreshList = {}, refreshProducts = [], total = 0, pices = 0
      for(let i = 0, item, id, pcs; i < action.payload.length; i++) {
				item = action.payload[i]
				id = parseInt(item.id)
				pcs = state.list[id]
				if(pcs > item.stock) pcs = item.stock
				if(item.stock >= item.min_cart_quantity) {
					if(pcs < item.min_cart_quantity) pcs = item.min_cart_quantity
					refreshList[id] = pcs
					refreshProducts.push(item)
					total += item.price * pcs
					pices += pcs
        }
      }
			state.list = refreshList
			state.products = refreshProducts
			state.total = total
			state.pices = pices
      window.localStorage.setItem('cart', JSON.stringify(refreshList))
		}
  }
})

export const { addCartItem, removeCartItem, updateCartItem, initCart } = cartSlice.actions
export default cartSlice.reducer
