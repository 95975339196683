import React from "react"
import { Link } from "react-router-dom"

const buttons = 7

export default function Paginate(props) {
	const url = new URLSearchParams()
	for(let k in props.url) {
		if(k !== 'lang' && props.url[k] !== '') {
			url.set(k, props.url[k])
		}
	}
	const pages = Math.ceil(props.total / props.url.limit)
	const page = props.url.page

	const arr = []
	let drop = [], pageNum, center
	if(pages > 1) {
		// prev & first
		pageNum = page > 2 ? page - 1 : 1
		if(pageNum > 1) url.set('page', pageNum)
		else url.delete('page')
		arr.push({
			key: 'prev',
			page: pageNum,
			query: '?' + url.toString(),
			classes: page > 1 ? 'prev' : 'prev disabled',
			content: '',
			drop: []
		})
		url.delete('page')
		arr.push({
			key: 'page1',
			page: 1,
			query: '?' + url.toString(),
			classes: page === 1 ? 'active' : '',
			content: 1,
			drop: []
		})
		if(pages <= buttons) {
			for(let i = 2; i < pages; i++) {
				url.set('page', i)
				arr.push({
					key: `page${i}`,
					page: i,
					query: '?' + url.toString(),
					classes: page === i ? 'active' : '',
					content: i,
					drop: []
				})
			}
		} else {
			if(page < buttons - 2) { // only right-drop
				for(let i = 2; i <= buttons - 2; i++) {
					url.set('page', i)
					arr.push({
						key: `page${i}`,
						page: i,
						query: '?' + url.toString(),
						classes: page === i ? 'active' : '',
						content: i,
						drop: []
					})
				}
				drop = []
				for(let i = buttons - 1; i < pages; i++) {
					url.set('page', i)
					drop.push({
						key: `page${i}`,
						page: i,
						query: '?' + url.toString(),
						classes: '',
						content: i,
					})
				}
				arr.push({
					key: 'drop-left',
					page: null,
					query: null,
					classes: 'drop-left',
					content: '…',
					drop: drop
				})
			} else if(page > pages - 4 ) { // only left-drop
				drop = []
				for(let i = 2; i < pages - 4; i++) {
					url.set('page', i)
					drop.push({
						key: `page${i}`,
						page: i,
						query: '?' + url.toString(),
						classes: '',
						content: i,
					})
				}
				arr.push({
					key: 'drop-left',
					page: null,
					query: null,
					classes: 'drop-left',
					content: '…',
					drop: drop
				})
				for(let i = pages - 4; i < pages; i++) {
					url.set('page', i)
					arr.push({
						key: `page${i}`,
						page: i,
						query: '?' + url.toString(),
						classes: page === i ? 'active' : '',
						content: i,
						drop: []
					})
				}
			} else { // both drops
				center = page
				if(center > 4) {
					drop = []
					for(let i = 2; i < center - 1; i++) {
						url.set('page', i)
						drop.push({
							key: `page${i}`,
							page: i,
							query: '?' + url.toString(),
							classes: '',
							content: i,
						})
					}
					arr.push({
						key: 'drop-left',
						page: null,
						query: null,
						classes: 'drop-left',
						content: '…',
						drop: drop
					})
				} else {
					for(let i = 2; i < 6; i++) {
						url.set('page', i)
						arr.push({
							key: `page${i}`,
							page: i,
							query: '?' + url.toString(),
							classes: page === i ? 'active' : '',
							content: i,
							drop: []
						})
					}
				}

				for(let i = center - 1; i <= center + 1; i++) {
					url.set('page', i)
					arr.push({
						key: `page${i}`,
						page: i,
						query: '?' + url.toString(),
						classes: page === i ? 'active' : '',
						content: i,
						drop: []
					})
				}

				if(pages - center > 2) {
					drop = []
					for(let i = center + 2; i < pages; i++) {
						url.set('page', i)
						drop.push({
							key: `page${i}`,
							page: i,
							query: '?' + url.toString(),
							classes: '',
							content: i,
						})
					}
					arr.push({
						key: 'drop-right',
						page: null,
						query: null,
						classes: 'drop-right',
						content: '…',
						drop: drop
					})
				} else {
					for(let i = center + 2; i < pages; i++) {
						url.set('page', i)
						arr.push({
							key: `page${i}`,
							page: i,
							query: '?' + url.toString(),
							classes: page === i ? 'active' : '',
							content: i,
							drop: []
						})
					}
				}
			}
		}

		// last & next
		url.set('page', pages)
		arr.push({
			key: `page${pages}`,
			page: pages,
			query: '?' + url.toString(),
			classes: page === pages ? 'active' : '',
			content: pages,
			drop: []
		})
		pageNum = page < pages ? page + 1 : pages
		url.set('page', pageNum)
		arr.push({
			key: 'next',
			page: pageNum,
			query: '?' + url.toString(),
			classes: page < pages ? 'next' : 'next disabled',
			content: '',
			drop: []
		})
			// console.log(arr)
		}

	const paginationRef = React.useRef()

	function open(e) {
		e.target.parentElement.classList.add('open')
	}
	function close(e) {
		setTimeout(() => {
			e.target.parentElement.classList.remove('open')
		}, 250)
	}

	return (
		<ul className='pagination short' ref={ paginationRef }>
			{ pages > 1 && arr.map(item =>
				<li className="page-link" key={ item.key }>
					{ item.classes.indexOf('drop') > -1
						? <button className={ 'link ' + item.classes } onClick={ (event) => open(event) } onBlur={ (event) => close(event) }>{ item.content }</button>
						: <Link className={ 'link ' + item.classes } to={ item.query } onClick={ () => props.setPage(item.page) }>{ item.content }</Link>
					}
					{ item.drop.length > 0 &&
						<div className="drop-wrapper">
							<ul>
								{ item.drop.map(drop => <li key={ drop.key }><Link to={ drop.query } onClick={ () => props.setPage(drop.page) }>{ drop.content }</Link></li> )}
							</ul>
						</div>
					}
				</li>
			)}
		</ul>
	)
}