import React from 'react'

export default function Waiting() {
	return (
		<div className="comp-waiting">
			<span></span>
			<span></span>
			<span></span>
			<span></span>
			<span></span>
			<span></span>
			<span></span>
		</div>
	)
}
