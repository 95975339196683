import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  settings: []
}

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSettings(state, action) { state.settings = action.payload },
    getSettings(state, action) { return state.settings }
  }
})

export const { setSettings, getSettings } = settingsSlice.actions
export default settingsSlice.reducer
