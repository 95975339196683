import React from "react"
// import { Link } from 'react-router-dom'

import { Swiper, SwiperSlide } from "swiper/react" // Import Swiper React components
import { Navigation, Pagination, Autoplay } from "swiper" // import required modules
import "swiper/css" // Import Swiper styles

import { __HOST__ } from '../const'
import Waiting from './Waiting'

import { useTranslation } from 'react-i18next'
import { usePromotionListQuery } from '../store/api/water.api'

const clearText = (str) => {
  let strip = document.createElement('div')
  strip.innerHTML = str
  return strip.innerText
}

export default function CarouselMain() {
  const { i18n } = useTranslation()
  const lang = i18n.language

  const [ promo, setPromo ] = React.useState([])

  const { isSuccess, data } = usePromotionListQuery({ lang: lang })
  React.useEffect(() => {
    isSuccess && setPromo(data.data.filter(slide => slide.images.length && slide.products.length))
  }, [data])

  // console.log('<CarouselMain/>', promo)

  return (
    !promo.length
    ? <Waiting/>
    : <section id="main-slider">
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        loop={true}
        autoplay={{
          delay: 7000,
          disableOnInteraction: true,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation, Autoplay]}
      >
        { promo.map(slide => (
          <SwiperSlide key={slide.id}>
              <img src={__HOST__ + slide.images[0].orig} alt={slide.name}/>
              <div className="description">
                <div className="title">{slide.name}</div>
                <p>{clearText(slide.text)}</p>
                {/* <Link to={__HOST__ + '/products/' + slide.id} className="btn link"><b>Learn More</b></Link> */}
              </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  )
}
