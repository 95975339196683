import React from "react"
// import { Link } from 'react-router-dom'

import { Swiper, SwiperSlide } from "swiper/react" // Import Swiper React components
import { Navigation } from "swiper" // import required modules
import "swiper/css" // Import Swiper styles

import { __HOST__ } from '../const'
import Waiting from './Waiting'

import { useTranslation } from 'react-i18next'
import { useNewsListQuery } from '../store/api/water.api'

const clearText = (str) => {
  let strip = document.createElement('div')
  strip.innerHTML = str
  return strip.innerText
}
export default function TapelMain() {
  const { t, i18n } = useTranslation()
  const lang = i18n.language

  const [ news, setNews ] = React.useState([])
  const { isSuccess, data } = useNewsListQuery({ lang })
  React.useEffect(() => {
    isSuccess && setNews(data.data)
  }, [data])

  return (
    !news.length
    ? <Waiting/>
    : <div className="area-content limited mt-4_0 mb-4_0">
        <div className="section-title tac ucase mb-0_5">{ t('lastNews') }</div>
        <section id="main-tape">
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={32}
            freeMode={true}
            navigation={true}
            modules={[Navigation]}
          >
            { news.map(slide => (
              <SwiperSlide key={ slide.id }>
                { slide.images.length > 0
                  ? <img src={ __HOST__+slide.images[0].md } alt={ slide.name }/>
                  : <img src="dist/img/no-photo.svg" alt={ slide.name } style={{ objectFit:'unset' }}/>
                }
                  <h5>{ slide.name }</h5>
                  <p className="mb-1_0 font-small">{clearText(slide.text)}</p>
                  {/* <p className=""><Link to={slide.link} className="font-small e-link"><b>Read More</b></Link></p> */}
              </SwiperSlide>
            ))}
          </Swiper>
        </section>
      </div>
  )
}
