import React from "react"
import { Link, useParams } from 'react-router-dom'
import { useProductDetailQuery } from '../store/api/water.api'
import { useTranslation } from "react-i18next"
import DOMPurify from 'dompurify'

import { useSelector,  useDispatch } from 'react-redux'
import { addCartItem, updateCartItem } from '../store/slices/cart.slice'

import Waiting from "../components/Waiting"
import { __HOST__ } from '../const'

const insertHtml = (html, attr = { classes: '', id: '' }) => {
  return (
    <div className={attr.classes} id={attr.id} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}></div>
  )
}

function makeString(obj) {
  let arr = []
  for(let i in obj) arr.push(i + ':' + obj[i])
  return arr.toString()
}

const oneClickBuy = (e) => {
  let oneClick = document.querySelector('.modal-window.modal-one-click'), fset = null, html = ''
  if(oneClick) {
    fset = oneClick.querySelector('fieldset')
    html += `<input type="hidden" name="form" value="oneClickProd"/>`
    html += `<input type="hidden" name="prod" value="${e.target.getAttribute('pid')}"/>`
    html += `<input type="hidden" name="cart" value="${e.target.getAttribute('items').replace(/"/g,'\\"')}"/>`
    fset.innerHTML = html
    oneClick.setAttribute('open', '')
    document.body.style.overflow = 'hidden'
  }
}

function setImage(e, img) {
  if(e.target.localName === 'li') {
    img.setAttribute('src', e.target.dataset.src)
    e.target.parentElement.querySelector('.active').classList.remove('active')
    e.target.classList.add('active')
  }
}

export default function PageDetails() {
	const { t, i18n } = useTranslation()

  const params = useParams()
  const queryOptions = {
    lang: i18n.language,
    fkProduct: params.id
  }
	const { isLoading, isError, isSuccess, data } = useProductDetailQuery(queryOptions)

  const cart = useSelector(state => state.cart)
  const dispatch = useDispatch()
  const addToCart = (e) => {
    let id = parseInt(e.target.getAttribute('pid')), pcs = typeof cart.list[id] === 'number' ? cart.list[id] : 0
    document.body.classList.add('waiting')
    if(pcs === 0 ) {
      dispatch(addCartItem(data))
    } else {
      if(data.data.stock > pcs) dispatch(updateCartItem({ id: id, pices: pcs + 1 }))
      else alert(t('errAddToCart'))
    }
    document.body.classList.remove('waiting')
    document.body.style.overflow = 'hidden'
    document.querySelector('.modal-window.modal-cart').setAttribute('open','')
  }

  const imageRef = React.useRef()

  return (
    <div className="area-content limited mt-2_0 mb-4_0">
			{ isLoading && <Waiting/> }
      { isError && (document.title = t('page404.title')) &&
        <>
          <h1 className="section-title tac ucase mb-0_5" style={{ color:'red' }}>{ t('page404.title') }</h1>
          <div className="half-width">
            <p className="tal mb-1_0">&larr;&nbsp;<Link to="/">{ t('page404.link') }</Link></p>
            <p className="tal">&larr;&nbsp;<button className="back" onClick={ () => window.history.back() }>{ t('page404.back') }</button></p>
          </div>
        </>
      }
      { isSuccess && (document.title = data.data.name) &&
        <>
          <p className="mb-3_0">&larr;&nbsp;<button className="back" onClick={ () => window.history.back() }>{ t('tocatalog') }</button></p>
          <div className="details-layout">
            <div className="image-area">
              <img src={ __HOST__ + data.data.images[0].orig } alt={ data.data.name} ref={ imageRef } />
              { data.data.images.length > 1 &&
              <ul className="thumb-list mt-0_5 mb-2_0" onClick={ event => setImage(event, imageRef.current) }>
                { data.data.images.map((img, i) => <li key={ i }><img src={ __HOST__ + img.sm } alt={ 'thumb_' + i }/></li>) }
              </ul>}
            </div>
            <div className="info-area">
              <h1 className="section-title ucase mt-0_0 mb-0_5">{ data.data.name }</h1>
              <div className="star-rating set mb-1_0" style={{'--rating': data.data.rating*100+'0%'}}></div>
              { (data.data.type === 'bottle') && <p>{ data.data.volume }</p> }
              <div className="mb-1_0">{ data.data.description }</div>
              <div className="price big mb-2_0">
                <span className="current">{ data.data.price }<i>{ t('currency') }</i></span>
                { data.data.price_old && <span className="old">{ data.data.price_old }<i>{ t('currency') }</i></span> }
              </div>
              {/* <div>{ data.data.stock }</div> */}
              {/* <p>{ data.data.min_cart_quantity }</p> */}
              <div className='btn-block'>
                <button className="btn sm" disabled={ data.data.stock < data.data.min_cart_quantity } pid={ data.data.id } cart={ cart.list[data.data.id] } onClick={ addToCart }><img src="/dist/img/ico/basket.svg" alt="add to cart"/>{ t('cartAdd') }</button>
                <button className="btn sm" disabled={ data.data.stock < data.data.min_cart_quantity } pid={ data.data.id } items={ makeString(cart.list) } onClick={ oneClickBuy }><img src="/dist/img/ico/icon-oneclick.svg" alt="buy in 1 click"/>{ t('cart1Click') }</button>
              </div>
            </div>
            <div className="desc-area">
            { insertHtml(data.data.text, { classes: 'area-article mb-2_0', id: '' }) }
            </div>
          </div>
        </>
      }
    </div>
	)
}
