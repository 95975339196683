import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function Page404() {
  const { t } = useTranslation()
	return (
		<>
      <div className="area-content limited mt-4_0 mb-4_0">
        <h1 className="section-title tac ucase mb-0_5" style={{ color:'red' }}>{ t('page404.title') }</h1>
				<div className="half-width">
					<p className="tal mb-1_0">&larr;&nbsp;<Link to="/">{ t('page404.link') }</Link></p>
					<p className="tal">&larr;&nbsp;<button className="back" onClick={ () => window.history.back() }>{ t('page404.back') }</button></p>
				</div>
			</div>
		</>
	)
}

export default Page404