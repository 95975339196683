import React from "react"
import { useProductsListQuery } from '../store/api/water.api'
import { useTranslation } from "react-i18next"

import Products from "../components/Products"
import Waiting from "../components/Waiting"
import Paginate from "../components/Paginate"
import Filter from "../components/Filter"

export default function PageProducts() {
  // const [ title, setTitle ] = React.useState('')
  // React.useEffect(() => {
  //   document.title = title
  // }, [title])

  const { t, i18n } = useTranslation()

  const urlParams = new URLSearchParams(window.location.search)
  // const [ promotion, setPromotion ] = React.useState(urlParams.get("hasPromotions") ? ~~urlParams.get("hasPromotions") : '')
  const [ stock, setStock ] = React.useState(urlParams.get("availableInStock") ? ~~urlParams.get("availableInStock") : '')
  const [ category, setCategory ] = React.useState(urlParams.get("fkCategory") ? ~~urlParams.get("fkCategory") : '')
  const [ limit, setLimit ] = React.useState(urlParams.get("limit") ? ~~urlParams.get("limit") : 6)
  const [ page, setPage ] = React.useState(urlParams.get("page") ? ~~urlParams.get("page") : 1)

  const queryOptions = {
    lang: i18n.language,
    limit: limit,
    page: page,
    // hasPromotions: promotion,
    availableInStock: stock,
    fkCategory: category
  }
	const { isLoading, isSuccess, data: prod } = useProductsListQuery(queryOptions)

  function resetFilter() {
    setPage(1)
    setLimit(6)
    setCategory('')
    // setPromotion('')
    setStock('')
  }

  const filterRef = React.useRef()
  function showFilter() { filterRef.current.classList.toggle('__show') }

  return (
		<>
			{ isLoading && <Waiting /> }
      { isSuccess &&
        <div className="area-content limited mt-4_0 mb-4_0">
          <div className="mb-1_0">
            <button className="btn filter-toggle" onClick={ () => showFilter() }>
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z"/>
              </svg>
              { t('filter.show') }
            </button>
          </div>
          <h1 className="section-title tac ucase mb-1_0">{ t("catalog") }</h1>
          <div className="prod-layout">
            <div className="filter-wrapper" ref={ filterRef }>
              <Filter
                showFilter={ showFilter }
                // setPromotion={ setPromotion }
                setCategory={ setCategory }
                setLimit={ setLimit }
                setPage={ setPage }
                setStock={ setStock }
                url={ queryOptions }
                reset={ resetFilter }
              />
            </div>
            <div className="prod-list">
              <Products items={ prod.data } />
              <Paginate
                total={ prod.total }
                url={ queryOptions }
                setPage={ setPage }
              />
            </div>
          </div>
        </div>
			}
		</>
	)
}
