import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  news: [],
	scoreboard: []
}

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
		setNews(state, action) { state.news = action.payload },
		getNews(state, action) { return state.news.news },
		setScoreboard(state, action) { state.scoreboard = action.payload },
    getScoreboard(state, action) { return state.scoreboard }
  }
})

export const { setNews, getNews, setScoreboard, getScoreboard } = newsSlice.actions
export default newsSlice.reducer
