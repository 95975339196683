import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const waterApi = createApi({
  reducerPath: 'waterapi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://wbtest.marat.ua/api/v1/',
		prepareHeaders(headers) {
			headers.set('Auth-Token', 'o3ta6c9OqMzjzf9D')
			headers.set('Access-Control-Allow-Origin', '*')
			return headers
		}
  }),
  endpoints: builder => ({
    productsList: builder.query({
      query: (params = { lang: 'ua' }) => ({
        url: `products/list`,
        params // fkCategory, show_on_main, page, limit, hasPromotions, availableInStock, minPrice, maxPrice, ids
      }),
      transformResponse: response => response
    }),
		productDetail: builder.query({
			query: (params = { lang: 'ua', fkProduct: '0' }) => ({
				url: `products/get`,
				params
			}),
			transformResponse: response => response
		}),
    productsCategories: builder.query({
      query: (params = { lang: 'ua' }) => ({
        url: `categories/list`,
        params
      }),
      transformResponse: response => response
    }),
    newsList: builder.query({
      query: (params = { lang: 'ua' }) => ({
        url: `news/list`,
        params // limit, page
      }),
      transformResponse: response => response
    }),
    promotionList: builder.query({
      query: (params = { lang: 'ua' }) => ({
        url: `promotions/list`,
        params // limit, page
      }),
      transformResponse: response => response
    }),
    settings: builder.query({
      query: () => ({
        url: `settings/get`
      }),
      transformResponse: response => response
    }),
    block: builder.query({
      query: (params = { lang: 'ua', alias: '' }) => ({
        url: `blocks/get`,
        params
      }),
      transformResponse: response => response
    }),
    test: builder.query({
      query: (params = { lang: 'ua' }) => ({
        url: `products/list`,
        params
      }),
      transformResponse: response => response,
      transformErrorResponse: response => response.status,
    }),
	})
})

export const {
  useProductsListQuery,
  usePromotionListQuery,
  useProductDetailQuery,
  useProductsCategoriesQuery,
  useSettingsQuery,
  useNewsListQuery,
  useBlockQuery,
  useTestQuery
} = waterApi
